<template>
  <div class="ManagerAllMissions">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <!--<CToaster :autohide="5000">
      <CToast
        class="danger-toast"
        :show="isTooMuchMissions"
      >
      <template #header>
        <strong class="text-danger">Vous avez {{missions.length}} missions en cours !</strong>
      </template>
      <template #default>
        <span>N'oubliez pas que vous pouvez <strong>archiver</strong> les missions qui sont terminées.</span><br>
        <small>Aucune donnée ne sera perdue, et vous pourrez à tout moment réactiver votre mission</small>

      </template>
      </CToast>
    </CToaster>-->

    <CRow>
      <CCol sm="6">
        <h1> Toutes les missions en cours </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('add-new-mission')"
          size="sm"
          shape="pill"
          color="success">
            <CIcon name="cil-plus"/> Créer une nouvelle mission
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
          <CRow>
            <CCol class="text-right">
              <a v-if="!isAllMissionsFilter"
                class="link-without-decoration"
                href="#" @click="filterAllMissions">
                Voir <strong>toutes</strong> les missions du cabinet
              </a>
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol md="6">
              <vue-bootstrap-typeahead
                ref="searchMissionClientAutocomplete"
                v-model="searchMissionClient"
                :data="clients"
                :serializer="item => item.name"
                @hit="searchMissionClientObject = $event; searchMissionByClient()"
                 placeholder="Chercher votre client par nom"
              />

              <small class="text-muted">
                Vous ne trouvez pas votre client ?
              </small>
              <router-link :to="{ name: 'Ajouter un nouveau client'}">
                <small class="text-muted">Ajouter un nouveau client en cliquant ici</small>
              </router-link>
            </CCol>
            <CCol md="6" class="mt-2 mt-md-0">
              <CForm v-on:submit.prevent="searchMissionByName()">
                <CInput
                  type="text" maxlength="250"
                  v-model.trim.lazy="searchMissionName"
                  @input="$v.searchMissionName.$touch()"
                  :state="$v.searchMissionName.$dirty ? !$v.searchMissionName.$error : null"
                  placeholder="Rechercher par nom de la mission">
                  <template #append>
                    <CButton
                      type="submit"
                      :disabled="$v.searchMissionName.$invalid"
                      color="outline-dark">
                      <CIcon name="cil-magnifying-glass"/>
                    </CButton>
                  </template>
                </CInput>
              </CForm>
            </CCol>
          </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardBody>
        <CRow v-if="userGroupContains(['MANAGER']) && missionsRender.length > 0">
          <CCol class="text-right">
            <exportTableDataComponent
              v-bind:data="missionsRender"
              v-bind:fields="missionFileRenderFields"
              name="Eclerk - Missions"
              label="Exporter la liste"
            />

          </CCol>
        </CRow>
        <CDataTable
          class="cursor-table"
          hover
          :items="missionsRender"
          :fields="missionsRenderFields"
          @row-clicked="goToMissionPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #client_is_firm="{item}">
          <td>
            <CIcon v-if="item.client_is_firm === true" name="cil-building"/>
            <CIcon v-else name="cil-user"/>
          </td>
        </template>
        <template #nom="{item}">
          <td>
           <span>{{item.nom}}</span>
           <div v-if="item.is_paiement_alert">
             <em class="text-danger">Alerte de paiement levée</em>
           </div>
          </td>
        </template>

        <template #types="{item}">
          <td>
            <em v-for="type in item.types" v-bind:key="type.id">{{type.name}} </em>
          </td>
        </template>

        <template #collaborateurs="{item}">
          <td>
            <em v-for="collaborateur in item.collaborateurs" v-bind:key="collaborateur.id">{{collaborateur.full_name}}, </em>
          </td>
        </template>

        <template #description="{item}">
          <td>
            <small> {{item.description}}</small>
          </td>
        </template>
        </CDataTable>
        <CRow>
          <CCol class="text-center">
            <CButton
              v-if="getAllMissionsUrl && isAllMissionsFilter"
              @click="getAllMissionsPaginator(false)"
              class="px-4"
              block
              shape="pill"
              color="outline-dark">
              Voir plus
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import userMixins from '@/mixins/userMixins'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManagerAllMissions',
  components: {
    Loading,
    VueBootstrapTypeahead,
    exportTableDataComponent
  },
  mixins: [
    validationMixin,
    userMixins
  ],
  data: function () {
    return {
      // ------------- MISSION -----------
      missions: [],
      missionsRender: [],
      missionsRenderFields: [
        { key: "client_is_firm", label: "", tdClass: 'ui-helper-center',},
        { key: "nom_du_client", label: "Nom du client", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "nom", label: "Intitulé mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "referent", label: "Référent mission", tdClass: 'ui-helper-center', _style: "min-width: 150px;"},
        { key: "collaborateurs", label: "Collaborateurs affectés", tdClass: 'ui-helper-center', _style: "min-width: 200px;" },
        { key: "description", label: "Description", tdClass: 'ui-helper-center', _style: "min-width: 200px;"},
      ],
      missionFileRenderFields: {
        'Client': 'nom_du_client',
        'Mission': 'nom',
        'Référent': 'referent',
        'Description': 'description',
      },
      isAllMissionsLoading: false,

      // ---- MISSIONS GETTETS --
      isAllMissionsFilter: true,

      baseGetAllMissionsUrl: apiBaseUrl + '/get-all-missions-paginator',
      getAllMissionsUrl: apiBaseUrl + '/get-all-missions-paginator',

      searchMissionName: '',

      clients: [],
      searchMissionClient: '',
      searchMissionClientObject: {
        id: '',
        name: ''
      },

      isTooMuchMissions: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllMissionsLoading || this.isAllPrioritiesLoading) {
        return true
      }
      return false
    }
  },
  validations: {
    searchMissionName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
  },
  created: function() {
    this.getAllMissionsPaginator()
  },
  watch: {
    missions: function (newMissions) {
      if (newMissions.length == 0) {
        this.missionsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'id': newMissions[i].id,
              'client_is_firm': newMissions[i].client_is_firm,
              'nom_du_client': newMissions[i].client_name,
              'nom': newMissions[i].name,
              'types': newMissions[i].types,
              'referent': newMissions[i].referent_full_name,
              'collaborateurs': newMissions[i].collaborateurs,
              'description': newMissions[i].description,
              'is_paiement_alert': newMissions[i].is_paiement_alert

            }
          )
        }
        this.missionsRender = final_array
      }

      /*if (this.missions.length > 30) {
        this.isTooMuchMissions = true
      }*/
    },

    // TODO When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    searchMissionClient(newQuery) {
      if (newQuery == '') {
        this.clients = []
        this.searchMissionClientObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.clients = res.data
        })
      }
    },
  },
  methods: {
    getAllMissionsPaginator (isReset) {
      this.isAllMissionsLoading = true
      if (isReset) {
        this.missions = []
        this.getAllMissionsUrl = this.baseGetAllMissionsUrl
      }
      apiManagerConnected.getAllMissionsPaginator(this.token, this.getAllMissionsUrl)
      .then((result) => {
        this.missions = this.missions.concat(result.data.results)
        this.getAllMissionsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllMissionsFilter = true
        this.isAllMissionsLoading = false
      })
    },

    searchMissionByName () {
      this.isAllMissionsLoading = true
      this.missions = []
      apiManagerConnected.searchMissionByName(this.token, this.searchMissionName)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllMissionsFilter = false
        this.isAllMissionsLoading = false
      })
    },



    searchMissionByClient () {
      this.isAllMissionsLoading = true
      this.missions = []
      apiUserConnected.getClientAllMissions(this.token, this.searchMissionClientObject.id)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllMissionsFilter = false
        this.isAllMissionsLoading = false
      })
    },


    filterAllMissions () {
      this.getAllMissionsUrl = this.baseGetAllMissionsUrl
      this.missions = []
      this.searchMissionClient = ''
      this.searchMissionClientObject = {
        id: '',
        name: ''
      }
      this.clients = []
      this.$refs.searchMissionClientAutocomplete.inputValue = ''

      this.searchMissionName = ''
      this.getAllMissionsPaginator(true)
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToMissionPage(item) {
      this.$router.push('/mission/' + item.id)
    }

  }

}
</script>

<style>
.toast {
  flex-basis: auto !important;
}
.danger-toast {
  background-color: #F8E0E0;
}
</style>
